export default {
  onboard: {
    address: {
      title: "Où se situe votre gisement solaire ?",
      subtitle: "Entrez votre addresse ci-dessous",
      buildingNotFoundDescription:
        "Votre batîment n'est pas celle indiqué par l'épingle ? Faites-la glisser sur le point exact.",
    },
    "electricity-bill": {
      title: "Votre facture d'électricité",
      description:
        "Cet espace est dédié à l’ajout de votre facture d’électricité récente. Elle nous permet de vérifier votre identité, d'établir votre type de contrat d'électricité, et de créer une prévision de votre nouvelle facture d'électricité en passant au solaire. Ces données sont personnelles et protégées ; elles ne sont utilisées sur notre plateforme que dans le cadre de votre projet solaire.",
      terms: "Pourquoi nous vous demandons votre facture d'électricité ?",
      tooltip: {
        title: "Veillez à ce que ces informations soient présentes :",
        requiredFields: {
          price: "Prix du kWh",
          size: "Puissance (kVa)",
          type: "Type de contrat (base/heure pleines heures creuses)",
          pdl: "Numéro PDL",
        },
      },
    },
    "enedis-mandate": {
      intro: {
        title:
          "Analyser les économies sur la facture à partir de l'historique horaire de consommation du compteur Linky",
        description:
          "En tant qu'entité référencée auprès d'Enedis, nous pouvons analyser votre courbe de charge pour calculer votre autoconsommation électrique heure par heure. C'est essentiel pour optimiser le dimensionnement de votre installation photovoltaïque, et obligatoire pour comparer les solutions de stockage",
        footer:
          "Veuillez-nous autoriser à obtenir de façon sécurisée les mesures horaires de votre historique de consommation",
        disclaimer:
          "Le consentement est autorisé au Mandataire la société élance Technologies Propres S.A.S, domiciliée 31 Avenue de Ségur, 75007 Paris, immatriculée au registre de commerce de Paris sous le numéro 878 632 306 opérant sous la marque déposée de « <strong>projetsolaire</strong> ». Pour plus d'informations <a href='https://projetsolaire.com/help/guides/installers/presentation/mandat-special-de-representation-aupres-denedis' target='_blank' class='text-indigo-600' >cliquez ici.</a>",
        button: "J’autorise le partage des mesures",
      },
      linky: {
        title: "Êtes-vous équipé d'un compteur Linky ?",
        description:
          "Si vous possédez un compteur Linky, nous pouvons procéder à l’analyse horaire de votre historique de consommation en kWh. Cela nous permettra de calculer votre potentiel d’autoconsommation électrique heure par heure, et vous réaliser ainsi une étude techniquement précise.",
        "no-linky": {
          title:
            "Sans Linky nous ne sommes pas en mesure de vous réaliser une étude technico-financière à partir de mesures horaires. Vous pouvez demander le remplacement gratuit de votre compteur actuel par un compteur Linky en",
          link: "",
        },
      },
      title: "Autorisation Linky",
      subtitle:
        "Afin de pouvoir étudier votre historique de consommation d'électricité depuis votre compteur Linky, votre accord est nécessaire pour accéder vos données personnelles, en tant qu’entité référencée auprès d’Enedis. Vos données sont protégées et ne seront utilisées et conservées qu'à cette fin.",
      form: {
        required: {
          enedisHistoricMeasurementsAccessGranted:
            "L'historique des mesures est requis.",
          enedisHistoricDailyConsumptionDataAccessGranted:
            "L'historique des relevés est requis.",
          enedisHistoricPowerDataAccessGranted:
            "L'historique de la puissance est requis.",
          enedisHistoricConsumptionLoadCurveDataAccessGranted:
            "L'historique de courbe est requis.",
          enedisContractAccessGranted:
            "Les données techniques et contractuelles sont requises.",
        },
      },
    },
    informations: {
      title: "Dernière étape",
      subtitle: "Pour obtenir votre étude gratuite.",
      firstname: "Prénom",
      lastname: "Nom",
      "company-name": "Entreprise",
      "company-siret": "Siret",
      phone: "Téléphone",
      password: "Mot de passe",
      "confirm-password": "Confirmez votre mot de passe",
      "see-stories": "Voir les autres histoires",
    },
    type: {
      title: "Vous êtes %{type}",
      RESIDENTIAL: "Un particulier",
      BUSINESS: "Une entreprise",
    },
    "project-type": {
      title: "Quel sera votre projet solaire sur-mesure ?",
      carport: "Carport",
      pergola: "Pergola",
      "ground-mount": "Au sol",
      tiles: "Sur toiture",
      "flat-roof": "Toiture terrasse",
      "solar-tiles": "Tuiles solaires",
    },
    "registration-success": {
      title: "Un email de confirmation vous a été envoyé",
      subtitle:
        "Votre étude est en cours de préparation ! Pour suivre son avancement consultez votre profil",
      question:
        "Avous-vous des questions ? Ou souhaitez-vous parler de votre projet avec une personne de notre équipe ?",
      calendly: "Prennez rendez-vous avec l'un de nos collaborateurs !",
    },
    location: "Emplacement",
    profile: "Profil",
    projectType: "Type de projet",
    "electricity-bill-document": "Facture électrique",
    consumptionData: "Donnée de conso",
    submit: "Envoyer ma demande",
  },
}
